
.ant-layout-sider {
  background: #2E3192 !important;
  transition: all 0.2s;
}

.ant-menu-inline {
  background: #2E3192 !important;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  background: #2E3192 !important;
}

.ant-layout-sider-trigger {
  background: #242772 !important;
}

.ant-layout-header {
  background: #242772 !important;
}

.ant-table-thead > tr > th {
  color: white !important;
  font-weight: bold;
  background-color: #ec8e2b !important;
}

h1 {
  font-weight: bolder !important;
}

.anticon .anticon-filter{
  color: white !important;
}

.ant-table-filter-trigger {
  color: white !important;
}

.table-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.ant-table {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ant-input, .ant-btn, .ant-picker, .ant-select-selector, .ant-select-single {
  border-radius: 5px !important;
  box-shadow:
  2.8px 2.8px 1.3px rgba(0, 0, 0, 0.014),
  6.7px 6.7px 3.1px rgba(0, 0, 0, 0.02),
  12.5px 12.5px 5.8px rgba(0, 0, 0, 0.025),
  22.3px 22.3px 10.3px rgba(0, 0, 0, 0.03),
  41.8px 41.8px 19.2px rgba(0, 0, 0, 0.036),
  100px 100px 46px rgba(0, 0, 0, 0.05)
;

}

